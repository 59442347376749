<template>
  <template-table
    :column="column"
    :data="groups"
    :paging="paging"
    :tableAction="false"
    :selectAction="false"
    :selectSingle="true"
    :searchAction="false"
    :pagingAction="true"
    @select-single="select"
    @sortBy="sortRequest"
  >
    <template v-slot:body="{ item }">
      <td class="text-left">{{ item.name }}</td>
      <td>
        <div class="d-flex align-items-center">
          <avatar
            size="40px"
            :text="item.healthCoachName"
            :src="item.healthCoachImage.url"
            :rounded="true"
          />
          <div class="d-flex flex-column ml-5">
            <p
              class="mb-0 module-list-page__body__updater-name font-weight-bolder"
            >
              {{ item.healthCoachName }}
            </p>
            <p
              class="mt-2 mb-0 module-list-page__body__updater-code text-dark-50"
            >
              Mã số: {{ item.healthCoachCode }}
            </p>
          </div>
        </div>
      </td>
      <td class="text-center">{{ item.patientPackageActive }}</td>
      <td class="text-left">{{ formatDate(item.createDatetime) }}</td>
      <td class="text-center">
        <a
          class="d-block a-link"
          href="javascript:void(0)"
          @click="openModalMapping(item)"
        >
          {{ item.mappingValue }}/11
        </a>
      </td>
    </template>
  </template-table>
</template>
<script>
export default {
  name: 'TableGroup',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    paging: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    column() {
      return [
        {
          label: 'Nhóm',
          key: 'name',
          sortable: true,
          class: 'text-left',
          style: {
            width: '15%',
          },
        },
        {
          label: 'Coach',
          class: 'text-left',
          key: 'coach',
          sortable: false,
          style: {
            width: '30%',
          },
        },
        {
          label: 'Số lương tham gia',
          key: 'maxMember',
          sortable: false,
          class: 'text-center',
          style: {
            width: '15%',
          },
        },
        {
          label: 'Thời gian tạo',
          key: 'date',
          sortable: false,
          class: 'text-left',
          style: {
            width: '15%',
          },
        },
        {
          key: 'mapping',
          label: 'Tiêu chí phù hợp',
          sortable: false,
          class: 'text-center',
          style: {
            width: '15%',
          },
        },
      ];
    },
  },
  methods: {
    select(params) {
      this.$emit('select', params);
    },
    sortRequest(params) {
      this.$emit('sortBy', params);
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    openModalMapping(item) {
      this.$emit('open-modal-mapping', { type: 'group', item });
    },
  },
};
</script>
<style lang=""></style>
